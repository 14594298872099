
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        
















.home-text-block {
  background-color: $c-white;
}

.home-text-block-inner {
  padding: $spacing * 1.25;
  background-color: $c-gray-lightest;
  transform: translateY(-50%);

  @include mq($until: m) {
    margin: 0 $spacing;
    transform: translateY(-25%);
  }

  @include mq(m) {
    display: flex;
    justify-content: space-between;
    padding: $spacing * 2.5 $spacing * 5;
  }
}

.home-text-block__text {
  max-width: 60rem;
  color: $c-blue-dark;

  @include mq($until: m) {
    margin-top: 1em;
  }
}
