
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        










.keyfact {
  @extend %text-center;

  @include mq($until: m) {
    text-transform: uppercase;
  }
}

.keyfact__icon {
  width: 15rem;
  height: 15rem;
  background-color: $c-gray-lightest;

  @include mq(m) {
    width: 24rem;
    height: 24rem;
  }
}
