
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        

















.home-mission {
  padding-bottom: $spacing * 3.5;
  background-color: $c-white;

  @include mq(m) {
    padding-bottom: $spacing * 7;
  }
}

.home-mission__intro {
  &::v-deep .intro-inner {
    margin: 0 auto;
    padding-top: 0;
  }
}

.home-mission__btn-outer {
  display: flex;
  justify-content: center;
}

.home-mission__btn {
  margin-top: $spacing;

  @include mq(m) {
    margin-top: $spacing * 4;
  }
}
