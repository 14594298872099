
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        





















.home-duo-inner {
  @include mq(m) {
    display: flex;
    flex-direction: row-reverse;
  }
}

.home-duo__picture {
  height: 25rem;

  @include mq(m) {
    flex-basis: 50%;
    height: auto;
  }
}

.home-duo__content {
  padding: $spacing * 4 $spacing * 2;

  @include mq(m) {
    flex-basis: 50%;
    max-width: 70rem;
    padding: $spacing * 6;
  }
}

.home-duo__content__text {
  margin-top: 2em;
  color: $c-white;
}

.home-duo__content__link {
  margin-top: 2em;
}
