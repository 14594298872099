
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        













.keyfacts-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.keyfacts-grid__item {
  flex-basis: 45%;

  @include mq(m) {
    flex-basis: 22%;
  }

  @include mq($until: m) {
    margin-bottom: $spacing * 2;
  }
}
