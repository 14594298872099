
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        

























.home {
  .first-load & {
    opacity: 0;
  }
}

.home__hero {
  height: 100vh;
}

.home__duo-outer {
  padding: $spacing * 2 0;

  @include mq(l) {
    padding: $spacing * 5 0;
  }
}
