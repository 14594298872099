
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        












.keypoints-grid {
  @include mq(m) {
    display: flex;
    justify-content: space-between;
  }
}

.keypoints-grid__item {
  @include mq(m) {
    flex-basis: 22%;
  }
}
