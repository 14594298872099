
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        


















.slidy {
  position: relative;
  height: 10rem;
  background-color: $c-dev-error;
}

.slidy__item {
  position: absolute;
  display: none;

  &.is-active {
    display: block;
  }
}
