
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        
















































.team-slider {
  position: relative;
}

.team-slider__next {
  position: absolute;
  z-index: 99;
  top: 0;
  right: 0;
  display: inline-block;
  width: 5rem;
  height: 5rem;
  pointer-events: none;
  transform: translate(50%, 0%);

  @include mq(l) {
    right: 100%;
    width: 8rem;
    height: 8rem;
    transform: translate(90%, 100%);
  }
}

.team-slider__next__progress {
  width: 5rem;
  height: 5rem;

  @include mq(l) {
    width: 8rem;
    height: 8rem;
  }
}

.team-slider__next__arrow {
  @include center-xy;

  width: 70%;
  height: 70%;
  fill: $c-blue-medium;
}

.team-slider__next__background {
  fill: transparent;
  stroke: $c-blue-light;
  stroke-width: 0.3rem;
}

.team-slider__next__circle {
  fill: transparent;
  stroke-dasharray: 600;
  stroke-dashoffset: 600;
  stroke: $c-blue-medium;
  stroke-width: 1rem;
}

.team-slider__list {
  position: relative;
  margin-bottom: $spacing;
  padding: $spacing 0;

  @include mq(m) {
    margin-bottom: $spacing * 3;
    padding: $spacing * 3 0;
  }

  @include mq(xl) {
    margin-bottom: $spacing * 6;
    padding: $spacing * 6 0;
  }
}

.slidy__item {
  position: absolute;
  display: flex;
  opacity: 0;

  &:first-child {
    z-index: 2;
    opacity: 1;
  }

  @include mq($until: m) {
    flex-wrap: wrap;
  }

  @include mq(m) {
    left: $spacing * 3.5;
  }
}

.team-slider__item {
  justify-content: space-between;
  width: 100%;
  height: 30rem;

  @include mq(m) {
    width: 90%;
    height: 60rem;
    margin-left: 5%;
  }
}

.team-slider__item__picture {
  z-index: 2;
  width: calc(100% / 3);
  height: 15rem;
  min-height: 15rem;
  background-color: transparent;

  @include mq(m) {
    width: 25rem;
    height: 38rem;
    margin: -5rem;

    &:first-child {
      right: 0;
    }

    &:nth-child(2n + 1) {
      top: $spacing * 2;
    }

    &:nth-child(2n) {
      right: $spacing * 4;
      align-self: flex-end;
    }

    &:nth-child(1),
    &:nth-child(4),
    &:nth-child(5) {
      z-index: 1;
      width: 22rem;
      height: 33rem;
    }
  }
}
