
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        

























.home-team {
  width: 100%;
  padding: $spacing * 2.5 0;
  // background-color: $c-gray-lightest;

  @include mq(m) {
    padding: $spacing * 4 0;
  }

  @include mq(l) {
    padding: $spacing * 6 0;
  }
}

.home-team__head {
  @include mq(m) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @include mq($until: m) {
    text-align: center;
  }
}

.home-team__title {
  width: 100%;
  max-width: 40rem;
}

.home-team__content {
  width: 100%;
  max-width: 60rem;
  margin-top: $spacing;

  @include mq(xl) {
    margin-right: $spacing * 6;
  }
}

.home-team__content__link {
  margin-top: $spacing;

  @include mq(l) {
    margin-top: $spacing * 2;
  }

  @include mq($until: m) {
    padding-bottom: $spacing;
  }
}

.home-team__keypoints {
  margin-top: $spacing * 2;

  @include mq(m) {
    margin-top: $spacing * 3;
  }

  @include mq(l) {
    margin-top: $spacing * 4;
  }
}
