
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        












.keypoint {
  @include mq($until: m) {
    display: flex;
    align-items: center;
    margin-bottom: $spacing * 2.5;
  }
}

.keypoint__number {
  @include mq($until: m) {
    flex-basis: 30%;
    text-align: center;
    color: $c-blue-dark;
  }
}

.keypoint__title {
  @include mq($until: m) {
    max-width: 15rem;
    margin-left: $spacing * 2;
    font-size: 1.8rem;
  }
}
