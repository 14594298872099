
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        



















.slider {
  position: relative;
  overflow: hidden;
  padding: $spacing * 5 0;
  background-color: $c-white;

  .flickity-enabled:focus {
    outline: none;
  }
}

.slider__list {
  @extend %list-nostyle;

  position: relative;
  overflow: visible;

  @include mq($until: l) {
    margin-top: $spacing * 2 !important;
  }
}

.slider__title {
  width: 31.5rem;
  margin: 0 auto;

  @include mq($until: l) {
    text-align: center;
  }

  @include mq(l) {
    position: absolute;
    margin-left: 0;
  }

  @include mq(xxxl) {
  }
}

.slider__item {
  width: 30rem;
  background-color: $c-gray-lightest;

  & + & {
    margin-left: $spacing * 2.5;
  }

  @include mq(m) {
    width: 40rem;
    transition: transform 0.5s $ease-out;

    &.lefted {
      transform: translateX(-120%);
    }
  }
}

.slider__item__picture-outer {
  position: relative;
  width: 100%;
  height: 20rem;

  @include mq(l) {
    height: 25rem;
  }
}

.slider__item__picture {
  @include image-fit;

  background-color: coral;
}

.slider__item__content {
  position: relative;
  padding: $spacing;

  @include mq(l) {
    padding: $spacing * 3;
  }
}

.slider__item__content__tag {
  @extend %fw-bold;

  position: absolute;
  top: 0;
  left: 0;
  padding: $spacing / 4 $spacing / 2;
  color: $c-white;
  font-size: 1.2rem;
  background-color: $c-blue-medium;
}

.slider__item__content__title {
  @include mq($until: l) {
    margin-top: $spacing * 2;
  }
}

.slider__item__content__text {
  margin-top: $spacing;
  font-size: 1.4rem;
  line-height: 1.8;

  @include mq(l) {
    margin-top: $spacing;
  }
}

.slider__item__content__date {
  @extend %fw-bold;

  margin-top: $spacing * 1.5;
  color: $c-gray-lighter;
  font-size: 1.4rem;
}

// Flickity related

.flickity-button {
  @extend %button-nostyle;

  position: relative;
  overflow: hidden;
  width: 6rem;
  height: 6rem;
  padding: 2rem;
  background-color: transparent;
  border: 1px solid $c-blue-medium;
  fill: $c-blue-medium;

  &::before {
    content: '';
    position: absolute;
    z-index: -2;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: transparent;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    display: block;
    width: 30vw;
    height: 30vw;
    background-color: $c-blue-light;
    transform: rotate(-45deg) translateY(-150%) translateX(-20%);
    transform-origin: top;
    transition: transform 0.5s ease-out;
  }

  &.previous {
    @include mq(l) {
      left: 0;
      transform: translateX(-100%);
    }
  }

  &.next {
    fill: $c-white;
    margin-left: -1px;

    &::before {
      background-color: $c-blue-medium;
    }

    @include mq(l) {
      left: 0;
      transform: translateX(-100%);
    }
  }

  @include mq(l) {
    &:hover {
      &::after {
        transform: rotate(-45deg) translateY(-60%) translateX(-20%);
      }
    }
  }

  @include mq($until: l) {
    margin-top: $spacing * 2;
  }

  @include mq(l) {
    position: absolute;
    bottom: 0;

    &.previous {
      transform: translateX(0);

      @include mq(xxxl) {
        left: 0;
      }
    }

    &.next {
      transform: translateX(100%);

      @include mq(xxxl) {
        left: 0;
      }
    }
  }
}

.flickity-button-icon {
  @include center-xy;

  width: 1.5rem;
  height: 1.5rem;
}
